<!--
 * @Author: your name
 * @Date: 2021-06-25 13:57:20
 * @LastEditTime: 2021-06-27 15:04:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /hx/examples/views/ComponentsLibrary/Foundation/Icon.vue
-->

<template>
  <div>
    <div class="main">
      <h2
        id="icon-tu-biao"
        style='box-sizing: border-box; margin: 0px; padding: 10px 0px; font-size: 28px; font-weight: 400; color: rgb(31, 47, 61); border-bottom: 1px solid rgb(197, 217, 232); font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; white-space: normal; background-color: rgb(255, 255, 255);'
      >
        <a href="#icon-tu-biao" class="header-anchor" style="box-sizing: border-box; color: rgb(16, 137, 255); text-decoration-line: none;">¶</a>&nbsp;Icon 图标
      </h2>
      <p
        style='box-sizing: border-box; margin-top: 1em; margin-bottom: 1em; padding: 0px; color: rgba(0, 0, 0, 0.85); font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 14px; white-space: normal; background-color: rgb(255, 255, 255);'
      >
        图标这里使用了阿里<a href="https://www.iconfont.cn/" style="box-sizing: border-box; color: rgb(16, 137, 255); text-decoration-line: none;">iconfont</a>图标库生成了图标，图标来源于 开源项目 Hippo Design，结合整理添加了一些其他的图标。
      </p>
      <p>
        <br />
      </p>

      <h3
        id="shi-yong-fang-fa"
        style='box-sizing: border-box; margin: 36px 0px 20px; padding: 0px; font-size: 22px; font-weight: 400; color: rgb(31, 47, 61); font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; white-space: normal; background-color: rgb(255, 255, 255);'
      >
        <a href="#shi-yong-fang-fa" class="header-anchor" style="box-sizing: border-box; color: rgb(16, 137, 255); text-decoration-line: none;">¶</a>&nbsp;使用方法
      </h3>
      <p
        style='box-sizing: border-box; margin-top: 1em; margin-bottom: 1em; padding: 0px; color: rgba(0, 0, 0, 0.85); font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 14px; white-space: normal; background-color: rgb(255, 255, 255);'
      >
        直接通过设置类名为 iconfont icon-layers 来使用即可。例如：
      </p>
      <p>
        <br />
      </p>
      <Demo>
        <div slot="source">
          <i class="lookicon iconfont icon-layers"></i>
          <i class="lookicon iconfont icon-lock"></i>
          <i class="lookicon iconfont icon-map"></i>
          <tb-button type="primary" icon="icon-search">搜索</tb-button>
        </div>
        <pre slot="highlight" v-highlight>
        <code class="javascript"  v-text="html1">
        </code>
      </pre>
      </Demo>
      <h3
        id="tu-biao-ji-he"
        style='box-sizing: border-box; margin: 36px 0px 20px; padding: 0px; font-size: 22px; font-weight: 400; color: rgb(31, 47, 61); font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; white-space: normal; background-color: rgb(255, 255, 255);'
      >
        <a href="#tu-biao-ji-he" class="header-anchor" style="box-sizing: border-box; color: rgb(16, 137, 255); text-decoration-line: none;">¶</a>&nbsp;图标集合
      </h3>
      <p>
        <br />
      </p>
      <div class="tab-container">
        <div class=" font-class">
          <ul class="icon_lists dib-box">
            <li class="dib">
              <span class="icon iconfont icon-layers"></span>
              <div class="name">layers</div>
              <div class="code-name">.icon-layers</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-lock"></span>
              <div class="name">lock</div>
              <div class="code-name">.icon-lock</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-fullscreen-expand"></span>
              <div class="name">fullscreen-expand</div>
              <div class="code-name">.icon-fullscreen-expand</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-map"></span>
              <div class="name">map</div>
              <div class="code-name">.icon-map</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-meh"></span>
              <div class="name">meh</div>
              <div class="code-name">.icon-meh</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-menu"></span>
              <div class="name">menu</div>
              <div class="code-name">.icon-menu</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-loading"></span>
              <div class="name">loading</div>
              <div class="code-name">.icon-loading</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-help"></span>
              <div class="name">help</div>
              <div class="code-name">.icon-help</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-minus-circle"></span>
              <div class="name">minus-circle</div>
              <div class="code-name">.icon-minus-circle</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-modular"></span>
              <div class="name">modular</div>
              <div class="code-name">.icon-modular</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-notification"></span>
              <div class="name">notification</div>
              <div class="code-name">.icon-notification</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-mic"></span>
              <div class="name">mic</div>
              <div class="code-name">.icon-mic</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-more"></span>
              <div class="name">more</div>
              <div class="code-name">.icon-more</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-pad"></span>
              <div class="name">pad</div>
              <div class="code-name">.icon-pad</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-operation"></span>
              <div class="name">operation</div>
              <div class="code-name">.icon-operation</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-play"></span>
              <div class="name">play</div>
              <div class="code-name">.icon-play</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-print"></span>
              <div class="name">print</div>
              <div class="code-name">.icon-print</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-mobile-phone"></span>
              <div class="name">mobile-phone</div>
              <div class="code-name">.icon-mobile-phone</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-minus"></span>
              <div class="name">minus</div>
              <div class="code-name">.icon-minus</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-navigation"></span>
              <div class="name">navigation</div>
              <div class="code-name">.icon-navigation</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-pdf"></span>
              <div class="name">pdf</div>
              <div class="code-name">.icon-pdf</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-prompt"></span>
              <div class="name">prompt</div>
              <div class="code-name">.icon-prompt</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-move"></span>
              <div class="name">move</div>
              <div class="code-name">.icon-move</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-refresh"></span>
              <div class="name">refresh</div>
              <div class="code-name">.icon-refresh</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-run-up"></span>
              <div class="name">run-up</div>
              <div class="code-name">.icon-run-up</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-picture"></span>
              <div class="name">picture</div>
              <div class="code-name">.icon-picture</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-run-in"></span>
              <div class="name">run-in</div>
              <div class="code-name">.icon-run-in</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-pin"></span>
              <div class="name">pin</div>
              <div class="code-name">.icon-pin</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-save"></span>
              <div class="name">save</div>
              <div class="code-name">.icon-save</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-search"></span>
              <div class="name">search</div>
              <div class="code-name">.icon-search</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-share"></span>
              <div class="name">share</div>
              <div class="code-name">.icon-share</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-scanning"></span>
              <div class="name">scanning</div>
              <div class="code-name">.icon-scanning</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-security"></span>
              <div class="name">security</div>
              <div class="code-name">.icon-security</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-sign-out"></span>
              <div class="name">sign-out</div>
              <div class="code-name">.icon-sign-out</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-select"></span>
              <div class="name">select</div>
              <div class="code-name">.icon-select</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-stop"></span>
              <div class="name">stop</div>
              <div class="code-name">.icon-stop</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-success"></span>
              <div class="name">success</div>
              <div class="code-name">.icon-success</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-smile"></span>
              <div class="name">smile</div>
              <div class="code-name">.icon-smile</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-switch"></span>
              <div class="name">switch</div>
              <div class="code-name">.icon-switch</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-setting"></span>
              <div class="name">setting</div>
              <div class="code-name">.icon-setting</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-survey"></span>
              <div class="name">survey</div>
              <div class="code-name">.icon-survey</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-task"></span>
              <div class="name">task</div>
              <div class="code-name">.icon-task</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-skip"></span>
              <div class="name">skip</div>
              <div class="code-name">.icon-skip</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-text"></span>
              <div class="name">text</div>
              <div class="code-name">.icon-text</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-time"></span>
              <div class="name">time</div>
              <div class="code-name">.icon-time</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-telephone-out"></span>
              <div class="name">telephone-out</div>
              <div class="code-name">.icon-telephone-out</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-toggle-left"></span>
              <div class="name">toggle-left</div>
              <div class="code-name">.icon-toggle-left</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-toggle-right"></span>
              <div class="name">toggle-right</div>
              <div class="code-name">.icon-toggle-right</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-telephone"></span>
              <div class="name">telephone</div>
              <div class="code-name">.icon-telephone</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-top"></span>
              <div class="name">top</div>
              <div class="code-name">.icon-top</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-unlock"></span>
              <div class="name">unlock</div>
              <div class="code-name">.icon-unlock</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-user"></span>
              <div class="name">user</div>
              <div class="code-name">.icon-user</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-upload"></span>
              <div class="name">upload</div>
              <div class="code-name">.icon-upload</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-work"></span>
              <div class="name">work</div>
              <div class="code-name">.icon-work</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-training"></span>
              <div class="name">training</div>
              <div class="code-name">.icon-training</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-warning"></span>
              <div class="name">warning</div>
              <div class="code-name">.icon-warning</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-zoom-in"></span>
              <div class="name">zoom-in</div>
              <div class="code-name">.icon-zoom-in</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-zoom-out"></span>
              <div class="name">zoom-out</div>
              <div class="code-name">.icon-zoom-out</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-add-bold"></span>
              <div class="name">add-bold</div>
              <div class="code-name">.icon-add-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-left-bold"></span>
              <div class="name">arrow-left-bold</div>
              <div class="code-name">.icon-arrow-left-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-up-bold"></span>
              <div class="name">arrow-up-bold</div>
              <div class="code-name">.icon-arrow-up-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-close-bold"></span>
              <div class="name">close-bold</div>
              <div class="code-name">.icon-close-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-down-bold"></span>
              <div class="name">arrow-down-bold</div>
              <div class="code-name">.icon-arrow-down-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-minus-bold"></span>
              <div class="name">minus-bold</div>
              <div class="code-name">.icon-minus-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-right-bold"></span>
              <div class="name">arrow-right-bold</div>
              <div class="code-name">.icon-arrow-right-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-select-bold"></span>
              <div class="name">select-bold</div>
              <div class="code-name">.icon-select-bold</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-up-filling"></span>
              <div class="name">arrow-up-filling</div>
              <div class="code-name">.icon-arrow-up-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-down-filling"></span>
              <div class="name">arrow-down-filling</div>
              <div class="code-name">.icon-arrow-down-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-left-filling"></span>
              <div class="name">arrow-left-filling</div>
              <div class="code-name">.icon-arrow-left-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-right-filling"></span>
              <div class="name">arrow-right-filling</div>
              <div class="code-name">.icon-arrow-right-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-caps-unlock-filling"></span>
              <div class="name">caps-unlock-filling</div>
              <div class="code-name">.icon-caps-unlock-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-comment-filling"></span>
              <div class="name">comment-filling</div>
              <div class="code-name">.icon-comment-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-check-item-filling"></span>
              <div class="name">check-item-filling</div>
              <div class="code-name">.icon-check-item-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-clock-filling"></span>
              <div class="name">clock-filling</div>
              <div class="code-name">.icon-clock-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-delete-filling"></span>
              <div class="name">delete-filling</div>
              <div class="code-name">.icon-delete-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-decline-filling"></span>
              <div class="name">decline-filling</div>
              <div class="code-name">.icon-decline-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-dynamic-filling"></span>
              <div class="name">dynamic-filling</div>
              <div class="code-name">.icon-dynamic-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-intermediate-filling"></span>
              <div class="name">intermediate-filling</div>
              <div class="code-name">.icon-intermediate-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-favorite-filling"></span>
              <div class="name">favorite-filling</div>
              <div class="code-name">.icon-favorite-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-layout-filling"></span>
              <div class="name">layout-filling</div>
              <div class="code-name">.icon-layout-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-help-filling"></span>
              <div class="name">help-filling</div>
              <div class="code-name">.icon-help-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-history-filling"></span>
              <div class="name">history-filling</div>
              <div class="code-name">.icon-history-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-filter-filling"></span>
              <div class="name">filter-filling</div>
              <div class="code-name">.icon-filter-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-file-common-filling"></span>
              <div class="name">file-common-filling</div>
              <div class="code-name">.icon-file-common-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-news-filling"></span>
              <div class="name">news-filling</div>
              <div class="code-name">.icon-news-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-edit-filling"></span>
              <div class="name">edit-filling</div>
              <div class="code-name">.icon-edit-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-fullscreen-expand-filling"></span>
              <div class="name">fullscreen-expand-filling</div>
              <div class="code-name">.icon-fullscreen-expand-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-smile-filling"></span>
              <div class="name">smile-filling</div>
              <div class="code-name">.icon-smile-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-rise-filling"></span>
              <div class="name">rise-filling</div>
              <div class="code-name">.icon-rise-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-picture-filling"></span>
              <div class="name">picture-filling</div>
              <div class="code-name">.icon-picture-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-notification-filling"></span>
              <div class="name">notification-filling</div>
              <div class="code-name">.icon-notification-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-user-filling"></span>
              <div class="name">user-filling</div>
              <div class="code-name">.icon-user-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-setting-filling"></span>
              <div class="name">setting-filling</div>
              <div class="code-name">.icon-setting-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-switch-filling"></span>
              <div class="name">switch-filling</div>
              <div class="code-name">.icon-switch-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-work-filling"></span>
              <div class="name">work-filling</div>
              <div class="code-name">.icon-work-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-task-filling"></span>
              <div class="name">task-filling</div>
              <div class="code-name">.icon-task-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-success-filling"></span>
              <div class="name">success-filling</div>
              <div class="code-name">.icon-success-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-warning-filling"></span>
              <div class="name">warning-filling</div>
              <div class="code-name">.icon-warning-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-folder-filling"></span>
              <div class="name">folder-filling</div>
              <div class="code-name">.icon-folder-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-map-filling"></span>
              <div class="name">map-filling</div>
              <div class="code-name">.icon-map-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-prompt-filling"></span>
              <div class="name">prompt-filling</div>
              <div class="code-name">.icon-prompt-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-meh-filling"></span>
              <div class="name">meh-filling</div>
              <div class="code-name">.icon-meh-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-cry-filling"></span>
              <div class="name">cry-filling</div>
              <div class="code-name">.icon-cry-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-top-filling"></span>
              <div class="name">top-filling</div>
              <div class="code-name">.icon-top-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-home-filling"></span>
              <div class="name">home-filling</div>
              <div class="code-name">.icon-home-filling</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-sorting"></span>
              <div class="name">sorting</div>
              <div class="code-name">.icon-sorting</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-3column"></span>
              <div class="name">column-3</div>
              <div class="code-name">.icon-3column</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-column-4"></span>
              <div class="name">column-4</div>
              <div class="code-name">.icon-column-4</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-add"></span>
              <div class="name">add</div>
              <div class="code-name">.icon-add</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-add-circle"></span>
              <div class="name">add-circle</div>
              <div class="code-name">.icon-add-circle</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-adjust"></span>
              <div class="name">adjust</div>
              <div class="code-name">.icon-adjust</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-up-circle"></span>
              <div class="name">arrow-up-circle</div>
              <div class="code-name">.icon-arrow-up-circle</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-right-circle"></span>
              <div class="name">arrow-right-circle</div>
              <div class="code-name">.icon-arrow-right-circle</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-down"></span>
              <div class="name">arrow-down</div>
              <div class="code-name">.icon-arrow-down</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-ashbin"></span>
              <div class="name">ashbin</div>
              <div class="code-name">.icon-ashbin</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-right"></span>
              <div class="name">arrow-right</div>
              <div class="code-name">.icon-arrow-right</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-browse"></span>
              <div class="name">browse</div>
              <div class="code-name">.icon-browse</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-bottom"></span>
              <div class="name">bottom</div>
              <div class="code-name">.icon-bottom</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-back"></span>
              <div class="name">back</div>
              <div class="code-name">.icon-back</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-bad"></span>
              <div class="name">bad</div>
              <div class="code-name">.icon-bad</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-double-left"></span>
              <div class="name">arrow-double-left</div>
              <div class="code-name">.icon-arrow-double-left</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-left-circle"></span>
              <div class="name">arrow-left-circle</div>
              <div class="code-name">.icon-arrow-left-circle</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-arrow-double-right"></span>
              <div class="name">arrow-double-right</div>
              <div class="code-name">.icon-arrow-double-right</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-caps-lock"></span>
              <div class="name">caps-lock</div>
              <div class="code-name">.icon-caps-lock</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-camera"></span>
              <div class="name">camera</div>
              <div class="code-name">.icon-camera</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-chart-bar"></span>
              <div class="name">chart-bar</div>
              <div class="code-name">.icon-chart-bar</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-attachment"></span>
              <div class="name">attachment</div>
              <div class="code-name">.icon-attachment</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-code"></span>
              <div class="name">code</div>
              <div class="code-name">.icon-code</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-close"></span>
              <div class="name">close</div>
              <div class="code-name">.icon-close</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-check-item"></span>
              <div class="name">check-item</div>
              <div class="code-name">.icon-check-item</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-calendar"></span>
              <div class="name">calendar</div>
              <div class="code-name">.icon-calendar</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-comment"></span>
              <div class="name">comment</div>
              <div class="code-name">.icon-comment</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-column-vertical"></span>
              <div class="name">column-vertical</div>
              <div class="code-name">.icon-column-vertical</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-column-horizontal"></span>
              <div class="name">column-horizontal</div>
              <div class="code-name">.icon-column-horizontal</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-complete"></span>
              <div class="name">complete</div>
              <div class="code-name">.icon-complete</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-chart-pie"></span>
              <div class="name">chart-pie</div>
              <div class="code-name">.icon-chart-pie</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-cry"></span>
              <div class="name">cry</div>
              <div class="code-name">.icon-cry</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-customer-service"></span>
              <div class="name">customer-service</div>
              <div class="code-name">.icon-customer-service</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-delete"></span>
              <div class="name">delete</div>
              <div class="code-name">.icon-delete</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-direction-down"></span>
              <div class="name">direction-down</div>
              <div class="code-name">.icon-direction-down</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-copy"></span>
              <div class="name">copy</div>
              <div class="code-name">.icon-copy</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-cut"></span>
              <div class="name">cut</div>
              <div class="code-name">.icon-cut</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-data-view"></span>
              <div class="name">data-view</div>
              <div class="code-name">.icon-data-view</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-direction-down-circle"></span>
              <div class="name">direction-down-circle</div>
              <div class="code-name">.icon-direction-down-circle</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-direction-right"></span>
              <div class="name">direction-right</div>
              <div class="code-name">.icon-direction-right</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-direction-up"></span>
              <div class="name">direction-up</div>
              <div class="code-name">.icon-direction-up</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-discount"></span>
              <div class="name">discount</div>
              <div class="code-name">.icon-discount</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-direction-left"></span>
              <div class="name">direction-left</div>
              <div class="code-name">.icon-direction-left</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-download"></span>
              <div class="name">download</div>
              <div class="code-name">.icon-download</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-electronics"></span>
              <div class="name">electronics</div>
              <div class="code-name">.icon-electronics</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-drag"></span>
              <div class="name">drag</div>
              <div class="code-name">.icon-drag</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-elipsis"></span>
              <div class="name">elipsis</div>
              <div class="code-name">.icon-elipsis</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-export"></span>
              <div class="name">export</div>
              <div class="code-name">.icon-export</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-explain"></span>
              <div class="name">explain</div>
              <div class="code-name">.icon-explain</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-edit"></span>
              <div class="name">edit</div>
              <div class="code-name">.icon-edit</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-eye-close"></span>
              <div class="name">eye-close</div>
              <div class="code-name">.icon-eye-close</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-email"></span>
              <div class="name">email</div>
              <div class="code-name">.icon-email</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-error"></span>
              <div class="name">error</div>
              <div class="code-name">.icon-error</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-favorite"></span>
              <div class="name">favorite</div>
              <div class="code-name">.icon-favorite</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-file-common"></span>
              <div class="name">file-common</div>
              <div class="code-name">.icon-file-common</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-file-delete"></span>
              <div class="name">file-delete</div>
              <div class="code-name">.icon-file-delete</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-file-add"></span>
              <div class="name">file-add</div>
              <div class="code-name">.icon-file-add</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-film"></span>
              <div class="name">film</div>
              <div class="code-name">.icon-film</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-fabulous"></span>
              <div class="name">fabulous</div>
              <div class="code-name">.icon-fabulous</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-file"></span>
              <div class="name">file</div>
              <div class="code-name">.icon-file</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-folder-close"></span>
              <div class="name">folder-close</div>
              <div class="code-name">.icon-folder-close</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-filter"></span>
              <div class="name">filter</div>
              <div class="code-name">.icon-filter</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-good"></span>
              <div class="name">good</div>
              <div class="code-name">.icon-good</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-hide"></span>
              <div class="name">hide</div>
              <div class="code-name">.icon-hide</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-home"></span>
              <div class="name">home</div>
              <div class="code-name">.icon-home</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-history"></span>
              <div class="name">history</div>
              <div class="code-name">.icon-history</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-file-open"></span>
              <div class="name">file-open</div>
              <div class="code-name">.icon-file-open</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-forward"></span>
              <div class="name">forward</div>
              <div class="code-name">.icon-forward</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-import"></span>
              <div class="name">import</div>
              <div class="code-name">.icon-import</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-image-text"></span>
              <div class="name">image-text</div>
              <div class="code-name">.icon-image-text</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-keyboard-26"></span>
              <div class="name">keyboard-26</div>
              <div class="code-name">.icon-keyboard-26</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-keyboard-9"></span>
              <div class="name">keyboard-9</div>
              <div class="code-name">.icon-keyboard-9</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-link"></span>
              <div class="name">link</div>
              <div class="code-name">.icon-link</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-layout"></span>
              <div class="name">layout</div>
              <div class="code-name">.icon-layout</div>
            </li>

            <li class="dib">
              <span class="icon iconfont icon-fullscreen-shrink"></span>
              <div class="name">fullscreen-shrink</div>
              <div class="code-name">.icon-fullscreen-shrink</div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <pre v-highlight>
        <code class="javascript"  v-text="html1">
        </code>
    </pre> -->
    <RightSmallNav :rightTitle="rightTitle" @goMeowPoint="goMeowPoint" />
  </div>
</template>

<script>
import useScroll from "../../../utils/mixins";
import RightSmallNav from "../../../components/RightSmallNav.vue";
export default {
  name: "Icon",
  components: { RightSmallNav },
  data() {
    return {
      html1: `    <i class="iconfont icon-layers"></i>
    <i class="iconfont icon-lock"></i>
    <i class="iconfont icon-map"></i>
    <tb-button type="primary" icon="icon-search">搜索</tb-button>
              `,
      //   定义当前传递过来的数据是所有类型的数据
      rightTitle: [
        { title: "Icon 图标", id: "icon-tu-biao" },
        { title: "使用方法", id: "shi-yong-fang-fa" },
        { title: "图标集合", id: "tu-biao-ji-he" },
      ],
    };
  },
  /**
   * @description: 引入minxns   src/utils/mixins.ts
   * @param {rightTitle}  rightTitle:右侧right浮起框数据
   * @return {*}
   */
  mixins: [useScroll],
  methods: {
    /**
     * @description: 点击右侧区域跳转至对应的html区域
     * @param {id:string} id:id节点地址
     * @return {*}
     */
    goMeowPoint(id) {
      const containern = window.document.querySelector("#" + id);
      containern.scrollIntoView(true);
    },
  },
};
</script>
<style lang="less" scoped>
.lookicon {
  font-size: 30px;
  margin: 5px 20px;
}
.main .logo {
  margin-top: 0;
  height: auto;
}

.main .logo a {
  display: flex;
  align-items: center;
}

.main .logo .sub-title {
  margin-left: 0.5em;
  font-size: 22px;
  color: #fff;
  background: linear-gradient(-45deg, #3967ff, #b500fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.logo {
  font-family: "iconfont logo";
  font-size: 160px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* tabs */
.nav-tabs {
  position: relative;
}

.nav-tabs .nav-more {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 42px;
  line-height: 42px;
  color: #666;
}

#tabs {
  border-bottom: 1px solid #eee;
}

#tabs li {
  cursor: pointer;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  color: #666;
}

#tabs .active {
  border-bottom-color: #f00;
  color: #222;
}

.tab-container .content {
  display: none;
}

/* 页面布局 */
.main {
  //   padding: 30px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.main .logo {
  color: #333;
  text-align: left;
  margin-bottom: 30px;
  line-height: 1;
  height: 110px;
  margin-top: -50px;
  overflow: hidden;
  *zoom: 1;
}

.main .logo a {
  font-size: 160px;
  color: #333;
}

.helps {
  margin-top: 40px;
}

.helps pre {
  padding: 20px;
  margin: 10px 0;
  border: solid 1px #e7e1cd;
  background-color: #fffdef;
  overflow: auto;
}

.icon_lists {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  overflow: hidden;
  *zoom: 1;
}

.icon_lists li {
  width: 100px;
  margin-bottom: 10px;
  margin-right: 20px;
  text-align: center;
  list-style: none !important;
  cursor: default;
}

.icon_lists li .code-name {
  line-height: 1.2;
}

.icon_lists .icon {
  display: block;
  height: 100px;
  line-height: 100px;
  font-size: 42px;
  margin: 10px auto;
  color: #333;
  -webkit-transition: font-size 0.25s linear, width 0.25s linear;
  -moz-transition: font-size 0.25s linear, width 0.25s linear;
  transition: font-size 0.25s linear, width 0.25s linear;
}

.icon_lists .icon:hover {
  font-size: 100px;
}

.icon_lists .svg-icon {
  /* 通过设置 font-size 来改变图标大小 */
  width: 1em;
  /* 图标和文字相邻时，垂直对齐 */
  vertical-align: -0.15em;
  /* 通过设置 color 来改变 SVG 的颜色/fill */
  fill: currentColor;
  /* path 和 stroke 溢出 viewBox 部分在 IE 下会显示
      normalize.css 中也包含这行 */
  overflow: hidden;
}

.icon_lists li .name,
.icon_lists li .code-name {
  color: #666;
}

/* markdown 样式 */
.markdown {
  color: #666;
  font-size: 14px;
  line-height: 1.8;
}

.highlight {
  line-height: 1.5;
}

.markdown img {
  vertical-align: middle;
  max-width: 100%;
}

.markdown h1 {
  color: #404040;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 24px;
}

.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  color: #404040;
  margin: 1.6em 0 0.6em 0;
  font-weight: 500;
  clear: both;
}

.markdown h1 {
  font-size: 28px;
}

.markdown h2 {
  font-size: 22px;
}

.markdown h3 {
  font-size: 16px;
}

.markdown h4 {
  font-size: 14px;
}

.markdown h5 {
  font-size: 12px;
}

.markdown h6 {
  font-size: 12px;
}

.markdown hr {
  height: 1px;
  border: 0;
  background: #e9e9e9;
  margin: 16px 0;
  clear: both;
}

.markdown p {
  margin: 1em 0;
}

.markdown > p,
.markdown > blockquote,
.markdown > .highlight,
.markdown > ol,
.markdown > ul {
  width: 80%;
}

.markdown ul > li {
  list-style: circle;
}

.markdown > ul li,
.markdown blockquote ul > li {
  margin-left: 20px;
  padding-left: 4px;
}

.markdown > ul li p,
.markdown > ol li p {
  margin: 0.6em 0;
}

.markdown ol > li {
  list-style: decimal;
}

.markdown > ol li,
.markdown blockquote ol > li {
  margin-left: 20px;
  padding-left: 4px;
}

.markdown code {
  margin: 0 3px;
  padding: 0 5px;
  background: #eee;
  border-radius: 3px;
}

.markdown strong,
.markdown b {
  font-weight: 600;
}

.markdown > table {
  border-collapse: collapse;
  border-spacing: 0px;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 95%;
  margin-bottom: 24px;
}

.markdown > table th {
  white-space: nowrap;
  color: #333;
  font-weight: 600;
}

.markdown > table th,
.markdown > table td {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}

.markdown > table th {
  background: #f7f7f7;
}

.markdown blockquote {
  font-size: 90%;
  color: #999;
  border-left: 4px solid #e9e9e9;
  padding-left: 0.8em;
  margin: 1em 0;
}

.markdown blockquote p {
  margin: 0;
}

.markdown .anchor {
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-left: 8px;
}

.markdown .waiting {
  color: #ccc;
}

.markdown h1:hover .anchor,
.markdown h2:hover .anchor,
.markdown h3:hover .anchor,
.markdown h4:hover .anchor,
.markdown h5:hover .anchor,
.markdown h6:hover .anchor {
  opacity: 1;
  display: inline-block;
}

.markdown > br,
.markdown > p > br {
  clear: both;
}

.hljs {
  display: block;
  background: white;
  padding: 0.5em;
  color: #333333;
  overflow-x: auto;
}

.hljs-comment,
.hljs-meta {
  color: #969896;
}

.hljs-string,
.hljs-variable,
.hljs-template-variable,
.hljs-strong,
.hljs-emphasis,
.hljs-quote {
  color: #df5000;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-type {
  color: #a71d5d;
}

.hljs-literal,
.hljs-symbol,
.hljs-bullet,
.hljs-attribute {
  color: #0086b3;
}

.hljs-section,
.hljs-name {
  color: #63a35c;
}

.hljs-tag {
  color: #333333;
}

.hljs-title,
.hljs-attr,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #795da3;
}

.hljs-addition {
  color: #55a532;
  background-color: #eaffea;
}

.hljs-deletion {
  color: #bd2c00;
  background-color: #ffecec;
}

.hljs-link {
  text-decoration: underline;
}

/* 代码高亮 */
/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
</style>
